<template>
  <div class="lay_container">
    <Header></Header>
    <div class="cont_m">
      <div class="max_cont_width">
        <div class="cop_title" style="justify-content: space-between;">
          <div class="cop_title">
            <img src="../../assets/img/detail/title_ic17.png" />COMPARE COUNTRY
          </div>
          <!-- <div class="flex_center"> -->
                    <div class="top_ic_btn no_login" @click="showTranslate = !showTranslate">
                        <img src="../../assets/img/detail/head_ic1.png" class="no_login" />
                    </div>
          
                </div>

        <div class="cop_cont_box">
          <div class="cop_cont_head flex_box">
            <div class="item">
              <el-select v-model="yearVal" placeholder="Select" @change="yearChange">
                <el-option v-for="item in yearList" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
            </div>
            <div class="item flex_center">{{ countryName }}</div>
            <div class="item flex_center">
              <el-select v-model="countryOneVal" @change="countryChange(2)" placeholder="Select Country" no-data-text="No Data" v-show="!countryOneVal">
                <el-option v-for="item in countryList" :key="item.countryCode" :label="item.nameEn" :value="item.countryCode"> </el-option>
              </el-select>
              <div class="delSelCountry flex_center" v-show="countryOneVal"><i class="del_ic" @click="delCountry(2)"></i>{{ countryOneValName }}</div>
            </div>
            <div class="item flex_center">
              <el-select v-model="countryTwoVal" @change="countryChange(3)" placeholder="Select Country" no-data-text="No Data" v-show="!countryTwoVal">
                <el-option v-for="item in countryList" :key="item.countryCode" :label="item.nameEn" :value="item.countryCode"> </el-option>
              </el-select>
              <div class="delSelCountry flex_center" v-show="countryTwoVal"><i class="del_ic" @click="delCountry(3)"></i>{{ countryTwoValName }}</div>
            </div>
          </div>
          <div class="cop_cont_cont flex_box">
            <div class="list content">
              <div class="item one">Year</div>
              <div class="item">
                <div class="item_title">Profile</div>
                <div class="menu_box">
                  <el-checkbox v-model="profileCheckAll" @change="handleProfileCheckAll('Profile')">All</el-checkbox>
                  <el-checkbox-group v-model="profileCheckList">
                    <el-checkbox v-for="(item, index) in profileList" :key="index" :label="item.name" @change="item.active = !item.active">
                      <span class="line_1 coll_child_name">{{ item.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="item">
                <div class="item_title">Economy</div>
                <div class="menu_box">
                  <el-checkbox v-model="economyCheckAll" @change="handleProfileCheckAll('Economy')">All</el-checkbox>
                  <el-checkbox-group v-model="economyCheckList">
                    <el-checkbox v-for="(item, index) in economyList" :key="index" :label="item.name" @change="item.active = !item.active">
                      <span class="line_1 coll_child_name">{{ item.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="item">
                <div class="item_title">Trade</div>
                <div class="menu_box">
                  <el-checkbox v-model="tradeCheckAll" @change="handleProfileCheckAll('Trade')">All</el-checkbox>
                  <el-checkbox-group v-model="tradeCheckList">
                    <el-checkbox v-for="(item, index) in tradeList" :key="index" :label="item.name" @change="item.active = !item.active">
                      <span class="line_1 coll_child_name">{{ item.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="item">
                <div class="item_title">Enterprises</div>
                <div class="menu_box">
                  <el-checkbox v-model="enterprisesCheckAll" @change="handleProfileCheckAll('Enterprises')">All</el-checkbox>
                  <el-checkbox-group v-model="enterprisesCheckList">
                    <el-checkbox v-for="(item, index) in enterprisesList" :key="index" :label="item.name" @change="item.active = !item.active">
                      <span class="line_1 coll_child_name">{{ item.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="item">
                <div class="item_title">Business</div>
                <div class="menu_box">
                  <el-checkbox v-model="businessCheckAll" @change="handleProfileCheckAll('Business')">All</el-checkbox>
                  <el-checkbox-group v-model="businessCheckList">
                    <el-checkbox v-for="(item, index) in businessList" :key="index" :label="item.name" @change="item.active = !item.active">
                      <span class="line_1 coll_child_name">{{ item.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="item">
                <div class="item_title">Credit Assessment</div>
                <div class="menu_box">
                  <el-checkbox v-model="creditCheckAll" @change="handleProfileCheckAll('Credit')">All</el-checkbox>
                  <el-checkbox-group v-model="creditCheckList">
                    <el-checkbox v-for="(item, index) in creditList" :key="index" :label="item.name" @change="item.active = !item.active">
                      <span class="line_1 coll_child_name">{{ item.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>

            <div class="list content">
              <div class="item one">{{ yearVal }}</div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ profileList[0].active ? profileObj.countryName : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[1].active ? profileObj.countryCode : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[2].active ? profileObj.region : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[3].active ? profileObj.capital : '' }}</div>
                  <div class="line_txt line_1" v-show="!showTranslate">{{ profileList[4].active ? profileObj.majorCity : '' }}</div>
                  <div class="line_txt line_1" v-show="showTranslate">{{ profileList[4].active ? profileObj.magorCityCn : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[5].active ? profileObj.landsize : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[6].active ? profileObj.population : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[7].active ? profileObj.populationGrowth : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[8].active ? profileObj.language : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[9].active ? profileObj.currency : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[10].active ? profileObj.foreignExchang : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ economyList[0].active ? economyObj.gdpPpp : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[1].active ? economyObj.gdpRank : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[2].active ? economyObj.gdpper : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[3].active ? economyObj.gdpperRank : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[4].active ? economyObj.economicGrowthRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[5].active ? economyObj.fdiInflows : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[6].active ? economyObj.fdiOutflows : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[7].active ? economyObj.foreignReserves : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[8].active ? economyObj.governmentDebt : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[9].active ? economyObj.governmentDebtToGdp : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[10].active ? economyObj.centralBankBalance : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[11].active ? economyObj.moneySupplyM1 : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[12].active ? economyObj.moneySupplyM2 : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[13].active ? economyObj.interestRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[14].active ? economyObj.inflationRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[15].active ? economyObj.consumerPriceIndex : '' }}</div>
                  <div class="line_txt line_1 no_m">{{ economyList[16].active ? economyObj.consumerConfidence : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ tradeList[0].active ? tradeObj.totalTrade : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[1].active ? tradeObj.totalExport : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[2].active ? tradeObj.totalImport : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[3].active ? tradeObj.tradeGoodsExports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[4].active ? tradeObj.tradeGoodsImports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[5].active ? tradeObj.tradeServicesExports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[6].active ? tradeObj.tradeServicesImports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[7].active ? tradeObj.tradeNetBalance : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[8].active ? tradeObj.tradeGap : '' }}</div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[9].active" v-for="item in tradeObj.topFiveExportingCountries" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[10].active" v-for="item in tradeObj.topFiveImportingCountries" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[11].active" v-for="item in tradeObj.topFiveExportingProducts" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[12].active" v-for="item in tradeObj.topFiveImportingProducts" :key="item">{{ item }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ enterprisesList[0].active ? enterprisesObj.totalOfEnterprises : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[1].active ? enterprisesObj.worldTop : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[2].active ? enterprisesObj.largeBusiness : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[3].active ? enterprisesObj.smes : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[4].active ? enterprisesObj.soleProprietor : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[5].active ? enterprisesObj.exceedThanTen : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[6].active ? enterprisesObj.betweenThreeTen : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[7].active ? enterprisesObj.lessThanThree : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[8].active ? enterprisesObj.majorCompanies : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[9].active ? enterprisesObj.majorBanks : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[10].active ? enterprisesObj.totalOfIndustries : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[11].active ? enterprisesObj.mainIndustries : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[12].active ? enterprisesObj.regionalDistribution : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ businessList[0].active ? businessObj.employmentRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[1].active ? businessObj.unemploymentRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[2].active ? businessObj.compositePmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[3].active ? businessObj.manufacturingPmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[4].active ? businessObj.servicesPmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[5].active ? businessObj.countryLoansToPrivateSector : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[6].active ? businessObj.corporateTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[7].active ? businessObj.personalIncomeTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[8].active ? businessObj.salesTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[9].active ? businessObj.businessConfidence : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ creditList[0].active ? creditObj.competitivenessIndex : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[1].active ? creditObj.competitivenessRank : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[2].active ? creditObj.easeOfDoingBusiness : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[3].active ? creditObj.businessConfidence : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[4].active ? creditObj.consumerConfidence : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[5].active ? creditObj.legalEnvironment : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[6].active ? creditObj.moody : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[7].active ? creditObj.fitch : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[8].active ? creditObj.dbrs : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[9].active ? creditObj.allianzTrade : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[10].active ? creditObj.coface : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[11].active ? creditObj.atradius : '' }}</div>
                  <div class="line_txt line_1 no_m">{{ creditList[12].active ? creditObj.oecd : '' }}</div>
                </div>
              </div>
            </div>
            <div class="list content">
              <div class="item one">{{ yearVal }}</div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ profileList[0].active ? profileObj2.countryName : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[1].active ? profileObj2.countryCode : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[2].active ? profileObj2.region : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[3].active ? profileObj2.capital : '' }}</div>
                  <div class="line_txt line_1" v-show="!showTranslate">{{ profileList[4].active ? profileObj2.majorCity : '' }}</div>
                  <div class="line_txt line_1" v-show="showTranslate">{{ profileList[4].active ? profileObj2.magorCityCn : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[5].active ? profileObj2.landsize : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[6].active ? profileObj2.population : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[7].active ? profileObj2.populationGrowth : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[8].active ? profileObj2.language : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[9].active ? profileObj2.currency : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[10].active ? profileObj2.foreignExchang : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ economyList[0].active ? economyObj2.gdpPpp : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[1].active ? economyObj2.gdpRank : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[2].active ? economyObj2.gdpper : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[3].active ? economyObj2.gdpperRank : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[4].active ? economyObj2.economicGrowthRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[5].active ? economyObj2.fdiInflows : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[6].active ? economyObj2.fdiOutflows : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[7].active ? economyObj2.foreignReserves : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[8].active ? economyObj2.governmentDebt : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[9].active ? economyObj2.governmentDebtToGdp : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[10].active ? economyObj2.centralBankBalance : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[11].active ? economyObj2.moneySupplyM1 : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[12].active ? economyObj2.moneySupplyM2 : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[13].active ? economyObj2.interestRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[14].active ? economyObj2.inflationRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[15].active ? economyObj2.consumerPriceIndex : '' }}</div>
                  <div class="line_txt line_1 no_m">{{ economyList[16].active ? economyObj2.consumerConfidence : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ tradeList[0].active ? tradeObj2.totalTrade : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[1].active ? tradeObj2.totalExport : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[2].active ? tradeObj2.totalImport : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[3].active ? tradeObj2.tradeGoodsExports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[4].active ? tradeObj2.tradeGoodsImports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[5].active ? tradeObj2.tradeServicesExports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[6].active ? tradeObj2.tradeServicesImports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[7].active ? tradeObj2.tradeNetBalance : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[8].active ? tradeObj2.tradeGap : '' }}</div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[9].active" v-for="item in tradeObj2.topFiveExportingCountries" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[10].active" v-for="item in tradeObj2.topFiveImportingCountries" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[11].active" v-for="item in tradeObj2.topFiveExportingProducts" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[12].active" v-for="item in tradeObj2.topFiveImportingProducts" :key="item">{{ item }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ enterprisesList[0].active ? enterprisesObj2.totalOfEnterprises : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[1].active ? enterprisesObj2.worldTop : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[2].active ? enterprisesObj2.largeBusiness : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[3].active ? enterprisesObj2.smes : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[4].active ? enterprisesObj2.soleProprietor : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[5].active ? enterprisesObj2.exceedThanTen : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[6].active ? enterprisesObj2.betweenThreeTen : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[7].active ? enterprisesObj2.lessThanThree : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[8].active ? enterprisesObj2.majorCompanies : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[9].active ? enterprisesObj2.majorBanks : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[10].active ? enterprisesObj2.totalOfIndustries : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[11].active ? enterprisesObj2.mainIndustries : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[12].active ? enterprisesObj2.regionalDistribution : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ businessList[0].active ? businessObj2.employmentRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[1].active ? businessObj2.unemploymentRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[2].active ? businessObj2.compositePmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[3].active ? businessObj2.manufacturingPmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[4].active ? businessObj2.servicesPmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[5].active ? businessObj2.countryLoansToPrivateSector : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[6].active ? businessObj2.corporateTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[7].active ? businessObj2.personalIncomeTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[8].active ? businessObj2.salesTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[9].active ? businessObj2.businessConfidence : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ creditList[0].active ? creditObj2.competitivenessIndex : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[1].active ? creditObj2.competitivenessRank : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[2].active ? creditObj2.easeOfDoingBusiness : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[3].active ? creditObj2.businessConfidence : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[4].active ? creditObj2.consumerConfidence : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[5].active ? creditObj2.legalEnvironment : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[6].active ? creditObj2.moody : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[7].active ? creditObj2.fitch : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[8].active ? creditObj2.dbrs : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[9].active ? creditObj2.allianzTrade : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[10].active ? creditObj2.coface : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[11].active ? creditObj2.atradius : '' }}</div>
                  <div class="line_txt line_1 no_m">{{ creditList[12].active ? creditObj2.oecd : '' }}</div>
                </div>
              </div>
            </div>
            <div class="list content">
              <div class="item one">{{ yearVal }}</div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ profileList[0].active ? profileObj3.countryName : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[1].active ? profileObj3.countryCode : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[2].active ? profileObj3.region : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[3].active ? profileObj3.capital : '' }}</div>
                  <div class="line_txt line_1" v-show="!showTranslate">{{ profileList[4].active ? profileObj3.majorCity : '' }}</div>
                  <div class="line_txt line_1" v-show="showTranslate">{{ profileList[4].active ? profileObj3.magorCityCn : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[5].active ? profileObj3.landsize : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[6].active ? profileObj3.population : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[7].active ? profileObj3.populationGrowth : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[8].active ? profileObj3.language : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[9].active ? profileObj3.currency : '' }}</div>
                  <div class="line_txt line_1">{{ profileList[10].active ? profileObj3.foreignExchang : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ economyList[0].active ? economyObj3.gdpPpp : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[1].active ? economyObj3.gdpRank : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[2].active ? economyObj3.gdpper : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[3].active ? economyObj3.gdpperRank : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[4].active ? economyObj3.economicGrowthRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[5].active ? economyObj3.fdiInflows : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[6].active ? economyObj3.fdiOutflows : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[7].active ? economyObj3.foreignReserves : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[8].active ? economyObj3.governmentDebt : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[9].active ? economyObj3.governmentDebtToGdp : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[10].active ? economyObj3.centralBankBalance : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[11].active ? economyObj3.moneySupplyM1 : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[12].active ? economyObj3.moneySupplyM2 : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[13].active ? economyObj3.interestRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[14].active ? economyObj3.inflationRate : '' }}</div>
                  <div class="line_txt line_1">{{ economyList[15].active ? economyObj3.consumerPriceIndex : '' }}</div>
                  <div class="line_txt line_1 no_m">{{ economyList[16].active ? economyObj3.consumerConfidence : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ tradeList[0].active ? tradeObj3.totalTrade : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[1].active ? tradeObj3.totalExport : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[2].active ? tradeObj3.totalImport : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[3].active ? tradeObj3.tradeGoodsExports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[4].active ? tradeObj3.tradeGoodsImports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[5].active ? tradeObj3.tradeServicesExports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[6].active ? tradeObj3.tradeServicesImports : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[7].active ? tradeObj3.tradeNetBalance : '' }}</div>
                  <div class="line_txt line_1">{{ tradeList[8].active ? tradeObj3.tradeGap : '' }}</div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[9].active" v-for="item in tradeObj3.topFiveExportingCountries" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[10].active" v-for="item in tradeObj3.topFiveImportingCountries" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[11].active" v-for="item in tradeObj3.topFiveExportingProducts" :key="item">{{ item }}</span>
                  </div>
                  <div class="line_txt line_1">
                    <span v-show="tradeList[12].active" v-for="item in tradeObj3.topFiveImportingProducts" :key="item">{{ item }}</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ enterprisesList[0].active ? enterprisesObj3.totalOfEnterprises : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[1].active ? enterprisesObj3.worldTop : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[2].active ? enterprisesObj3.largeBusiness : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[3].active ? enterprisesObj3.smes : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[4].active ? enterprisesObj3.soleProprietor : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[5].active ? enterprisesObj3.exceedThanTen : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[6].active ? enterprisesObj3.betweenThreeTen : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[7].active ? enterprisesObj3.lessThanThree : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[8].active ? enterprisesObj3.majorCompanies : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[9].active ? enterprisesObj3.majorBanks : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[10].active ? enterprisesObj3.totalOfIndustries : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[11].active ? enterprisesObj3.mainIndustries : '' }}</div>
                  <div class="line_txt line_1">{{ enterprisesList[12].active ? enterprisesObj3.regionalDistribution : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ businessList[0].active ? businessObj3.employmentRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[1].active ? businessObj3.unemploymentRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[2].active ? businessObj3.compositePmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[3].active ? businessObj3.manufacturingPmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[4].active ? businessObj3.servicesPmi : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[5].active ? businessObj3.countryLoansToPrivateSector : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[6].active ? businessObj3.corporateTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[7].active ? businessObj3.personalIncomeTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[8].active ? businessObj3.salesTaxRate : '' }}</div>
                  <div class="line_txt line_1">{{ businessList[9].active ? businessObj3.businessConfidence : '' }}</div>
                </div>
              </div>
              <div class="item">
                <div class="check_line">
                  <div class="line_txt line_1">{{ creditList[0].active ? creditObj3.competitivenessIndex : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[1].active ? creditObj3.competitivenessRank : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[2].active ? creditObj3.easeOfDoingBusiness : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[3].active ? creditObj3.businessConfidence : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[4].active ? creditObj3.consumerConfidence : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[5].active ? creditObj3.legalEnvironment : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[6].active ? creditObj3.moody : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[7].active ? creditObj3.fitch : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[8].active ? creditObj3.dbrs : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[9].active ? creditObj3.allianzTrade : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[10].active ? creditObj3.coface : '' }}</div>
                  <div class="line_txt line_1">{{ creditList[11].active ? creditObj3.atradius : '' }}</div>
                  <div class="line_txt line_1 no_m">{{ creditList[12].active ? creditObj3.oecd : '' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { getCountryCollection, getCompanySnap } from '@/api/companyApi.js'
import { searchCountryInfo } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  components: { Header, Footer },
  data() {
    return {
      yearVal: new Date().getFullYear()-1,
      countryName: '',
      yearList: [],
      countryOneVal: '',
      countryTwoVal: '',
      countryOneValName: '',
      countryTwoValName: '',
      countryList: [],
      profileCheckAll: false,
      profileCheckList: [],
      profileList: [
        { name: 'Official State Name', active: false },
        { name: 'Country ISO Code', active: false },
        { name: 'Region', active: false },
        { name: 'Capital', active: false },
        { name: 'Major City', active: false },
        { name: 'Land size', active: false },
        { name: 'Population', active: false },
        { name: 'Population Growth', active: false },
        { name: 'Official Language', active: false },
        { name: 'Currency', active: false },
        { name: 'Foreign Exchange', active: false },
      ],
      profileObj: {
        countryName: '',
        countryCode: '',
        region: '',
        capital: '',
        majorCity: '',
        landsize: '',
        population: '',
        populationGrowth: '',
        language: '',
        currency: '',
        foreignExchang: '',
      },
      profileObj2: {},
      profileObj3: {},
      economyCheckAll: false,
      economyCheckList: [],
      economyList: [
        { name: 'GDP (PPP)', active: false },
        { name: 'GDP Rank', active: false },
        { name: 'GDP/Capita', active: false },
        { name: 'GDP/Capita Rank', active: false },
        { name: 'Economic Growth Rate', active: false },
        { name: 'FDI Inflow', active: false },
        { name: 'FDI Outflow', active: false },
        { name: 'Foreign Reserves', active: false },
        { name: 'Government Debt', active: false },
        { name: 'Government Debt to GDP', active: false },
        { name: 'Central Bank Balance Sheet', active: false },
        { name: 'Money Supply M1', active: false },
        { name: 'Money Supply M2', active: false },
        { name: 'Interest Rate', active: false },
        { name: 'Inflation Rate', active: false },
        { name: 'Consumer Price Index (CPI)', active: false },
        { name: 'Consumer Confidence', active: false },
      ],
      economyObj: {
        gdpPpp: '',
        gdpRank: '',
        gdpper: '',
        gdpperRank: '',
        economicGrowthRate: '',
        fdiInflows: '',
        fdiOutflows: '',
        foreignReserves: '',
        governmentDebt: '',
        governmentDebtToGdp: '',
        centralBankBalance: '',
        moneySupplyM1: '',
        moneySupplyM2: '',
        interestRate: '',
        inflationRate: '',
        consumerPriceIndex: '',
        consumerConfidence: '',
      },
      economyObj2: {},
      showTranslate: false,
      economyObj3: {},
      tradeCheckAll: false,
      tradeCheckList: [],
      tradeList: [
        { name: 'Total Trade Amount', active: false },
        { name: 'Total Export', active: false },
        { name: 'Total Import', active: false },
        { name: 'Trade Goods Export', active: false },
        { name: 'Trade Goods Import', active: false },
        { name: 'Trade Services Export', active: false },
        { name: 'Trade Services Import', active: false },
        { name: 'Trade Net Balance', active: false },
        { name: 'Trade Gap', active: false },
        { name: 'Top 5 Countries Export To', active: false },
        { name: 'Top 5 Countries Import From', active: false },
        { name: 'Top 5 Exported Products', active: false },
        { name: 'Top 5 Imported Products', active: false },
      ],
      tradeObj: {
        totalTrade: '',
        totalExport: '',
        totalImport: '',
        tradeGoodsExports: '',
        tradeGoodsImports: '',
        tradeServicesExports: '',
        tradeServicesImports: '',
        tradeNetBalance: '',
        tradeGap: '',
        topFiveExportingCountries: [],
        topFiveImportingCountries: [],
        topFiveExportingProducts: [],
        topFiveImportingProducts: [],
      },
      tradeObj2: {},
      tradeObj3: {},
      enterprisesCheckAll: false,
      enterprisesCheckList: [],
      enterprisesList: [
        { name: 'Total No. Of Enterprises', active: false },
        { name: 'Fortune 500', active: false },
        { name: 'Large Business', active: false },
        { name: 'SMEs', active: false },
        { name: 'Sole Proprietor', active: false },
        { name: 'No. of Co. over 10 Years', active: false },
        { name: 'No. of Co. Between 3-10 Years', active: false },
        { name: 'No. of Co. L ess Than 3 Years', active: false },
        { name: 'Major Companies', active: false },
        { name: 'Major Banks', active: false },
        { name: 'Total No. of Industries', active: false },
        { name: 'Main Industries', active: false },
        { name: 'Regional Distribution', active: false },
      ],
      enterprisesObj: {
        totalOfEnterprises: '',
        worldTop: '',
        largeBusiness: '',
        smes: '',
        soleProprietor: '',
        exceedThanTen: '',
        betweenThreeTen: '',
        lessThanThree: '',
        majorCompanies: '',
        majorBanks: '',
        totalOfIndustries: '',
        mainIndustries: '',
        regionalDistribution: '',
      },
      enterprisesObj2: {},
      enterprisesObj3: {},
      businessCheckAll: false,
      businessCheckList: [],
      businessList: [
        { name: 'Employment', active: false },
        { name: 'Unemployment', active: false },
        { name: 'Composite PMI', active: false },
        { name: 'Manufacturing PMI', active: false },
        { name: 'Services PMI', active: false },
        { name: 'Country Loans to Private Sector', active: false },
        { name: 'Corporate Tax Rate', active: false },
        { name: 'Personal Income Tax Rate', active: false },
        { name: 'Sales Tax Rate', active: false },
        { name: 'Business Confidence', active: false },
      ],
      businessObj: {
        employmentRate: '',
        unemploymentRate: '',
        compositePmi: '',
        manufacturingPmi: '',
        servicesPmi: '',
        countryLoansToPrivateSector: '',
        corporateTaxRate: '',
        personalIncomeTaxRate: '',
        salesTaxRate: '',
        businessConfidence: '',
      },
      businessObj2: {},
      businessObj3: {},
      creditCheckAll: false,
      creditCheckList: [],
      creditList: [
        { name: 'Competitiveness Index', active: false },
        { name: 'Competitiveness Rank', active: false },
        { name: 'Ease of Doing Business', active: false },
        { name: 'Business Confidence', active: false },
        { name: 'Consumer Confidence', active: false },
        { name: 'Legal Environment', active: false },
        { name: "Moody's Rating", active: false },
        { name: 'Fitch Rating', active: false },
        { name: 'DBRS Rating', active: false },
        { name: 'Allianz Rating', active: false },
        { name: 'Coface Rating', active: false },
        { name: 'Atradius Rating', active: false },
        { name: 'OECD Rating', active: false },
      ],
      creditObj: {
        competitivenessIndex: '',
        competitivenessRank: '',
        easeOfDoingBusiness: '',
        businessConfidence: '',
        consumerConfidence: '',
        legalEnvironment: '',
        moody: '',
        fitch: '',
        dbrs: '',
        allianzTrade: '',
        coface: '',
        atradius: '',
        oecd: '',
      },
      creditObj2: {},
      creditObj3: {},
      companyCountry: '',
    }
  },
  created() {
    this.getData(1)
    // this.getYears()
  },
  methods: {
    getData(num) {
      return new Promise((resolve, reject) => {
        this.getAllCountry().then((r) => {
          if (num == 1) {
            this.companyCountry = this.$route.query.companyCountry
            let nameObj = {}
            this.countryList.filter((item) => {
              if (item.countryCode == this.companyCountry) {
                nameObj = item
                return false
              }
            })
            this.countryName = nameObj.nameEn
          } else if (num == 2) {
            this.companyCountry = this.countryOneVal
            let nameObj = {}
            this.countryList.filter((item) => {
              if (item.countryCode == this.countryOneVal) {
                nameObj = item
                return false
              }
            })
            this.countryOneValName = nameObj.nameEn
          } else {
            this.companyCountry = this.countryTwoVal
            let nameObj = {}
            this.countryList.filter((item) => {
              if (item.countryCode == this.countryTwoVal) {
                nameObj = item
                return false
              }
            })
            this.countryTwoValName = nameObj.nameEn
          }

          const params = {
            types: ['CE', 'CP', 'CB', 'CR', 'CT', 'CC'],
            countryCodes: [this.companyCountry],//, this.countryOneVal, this.countryTwoVal
            years: [this.yearVal],
          }
          searchCountryInfo(params).then((result) => {
            let data = JSON.parse(crypto.decrypt(result))
            if (data && data.code && data.data) {
              console.log('result- compare', data.data)
              let obj = data.data
              const profileObj = obj.countryProfileList[0]
              const economyObj = obj.countryEconomyList[0]
              const tradeObj = obj.countryTradeList[0]
              const enterprisesObj = obj.countryCompanyList[0]
              const businessObj = obj.countryBusinessList[0]
              const creditObj = obj.countryRatingsList[0]
              this.yearList=obj.years
              // console.log(` this.yearList`,  this.yearList);
              if (num == 1) {
                this.profileObj = profileObj
                this.economyObj = economyObj
                this.tradeObj = tradeObj
                this.enterprisesObj = enterprisesObj
                this.businessObj = businessObj
                this.creditObj = creditObj
              } else if (num == 2) {
                this.profileObj2 = profileObj
                this.economyObj2 = economyObj
                this.tradeObj2 = tradeObj
                this.enterprisesObj2 = enterprisesObj
                this.businessObj2 = businessObj
                this.creditObj2 = creditObj
              } else if (num == 3) {
                this.profileObj3 = profileObj
                this.economyObj3 = economyObj
                this.tradeObj3 = tradeObj
                this.enterprisesObj3 = enterprisesObj
                this.businessObj3 = businessObj
                this.creditObj3 = creditObj
              }
            }
          })
        })
        resolve(true)
      });
    },
    //获取国家
    async getAllCountry() {
      let res = await getCountryCollection()
      if (res && res.code) {
        this.countryList = res.data.rows[0].countryList
      }
    },
    // getYears() {
    //   const loadYear = new Date().getFullYear()

    //   for (var i = 0; i < 4; i++) {
    //     const year = loadYear - i
    //     this.yearList.push(year)
    //   }
    // },
    yearChange() {
      this.getData(1).then(()=>{
        if(Object.keys(this.profileObj2).length!=0){
          this.getData(2)
        }
        if(Object.keys(this.profileObj3).length!=0){
          this.getData(3)
        }
      })


    },
    countryChange(n) {
      this.getData(n)
    },
    delCountry(n) {
      if (n == 2) {
        this.countryOneVal = ''
        this.profileObj2 = ''
        this.economyObj2 = ''
        this.tradeObj2 = ''
        this.enterprisesObj2 = ''
        this.businessObj2 = ''
        this.creditObj2 = ''
      } else {
        this.countryTwoVal = ''
        this.profileObj3 = ''
        this.economyObj3 = ''
        this.tradeObj3 = ''
        this.enterprisesObj3 = ''
        this.businessObj3 = ''
        this.creditObj3 = ''
      }
    },
    //全选 Profile
    handleProfileCheckAll(val) {
      if (val == 'Profile') {
        if (this.profileCheckAll) {
          this.profileList.forEach((value) => {
            if (this.profileCheckList.indexOf(value.name) < 0) {
              this.profileCheckList.push(value.name)
              value.active = true
            }
          })
        } else {
          this.profileList.forEach((value) => {
            value.active = false
          })
          this.profileCheckList = []
        }
      } else if (val == 'Economy') {
        if (this.economyCheckAll) {
          this.economyList.forEach((value) => {
            if (this.economyCheckList.indexOf(value.name) < 0) {
              this.economyCheckList.push(value.name)
              value.active = true
            }
          })
        } else {
          this.economyList.forEach((value) => {
            value.active = false
          })
          this.economyCheckList = []
        }
      } else if (val == 'Trade') {
        if (this.tradeCheckAll) {
          this.tradeList.forEach((value) => {
            if (this.tradeCheckList.indexOf(value.name) < 0) {
              this.tradeCheckList.push(value.name)
              value.active = true
            }
          })
        } else {
          this.tradeList.forEach((value) => {
            value.active = false
          })
          this.tradeCheckList = []
        }
      } else if (val == 'Enterprises') {
        if (this.enterprisesCheckAll) {
          this.enterprisesList.forEach((value) => {
            if (this.enterprisesCheckList.indexOf(value.name) < 0) {
              this.enterprisesCheckList.push(value.name)
              value.active = true
            }
          })
        } else {
          this.enterprisesList.forEach((value) => {
            value.active = false
          })
          this.enterprisesCheckList = []
        }
      } else if (val == 'Business') {
        if (this.businessCheckAll) {
          this.businessList.forEach((value) => {
            if (this.businessCheckList.indexOf(value.name) < 0) {
              this.businessCheckList.push(value.name)
              value.active = true
            }
          })
        } else {
          this.businessList.forEach((value) => {
            value.active = false
          })
          this.businessCheckList = []
        }
      } else {
        if (this.creditCheckAll) {
          this.creditList.forEach((value) => {
            if (this.creditCheckList.indexOf(value.name) < 0) {
              this.creditCheckList.push(value.name)
              value.active = true
            }
          })
        } else {
          this.creditList.forEach((value) => {
            value.active = false
          })
          this.creditCheckList = []
        }
      }
    },
  },
}
</script>
<style scoped>
.no_m {
  margin-bottom: 0;
}
.cont_m {
  margin: 0px auto 100px;
}
.cop_title img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.cop_title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Arial Bold';
  margin-top: 20px;
  margin-bottom: 20px;
}
.cop_cont_box {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
  border-radius: 14px;
  padding-top: 60px;
}
/* .el-dialog__wrapper {
  margin-top: 30px;
    overflow: visible;
    z-index: 2006!important;
    position: fixed;
    top: 72px;
    right: 0;
    bottom: 0;
    left: 0;
} */
.cop_cont_head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 10px 5px 10px 20px;
  border-radius: 14px 14px 0px 0px;
  border: 1px solid #e7eaed;
  background: #f2f5f9;
  color: #8497ab;
  font-size: 14px;
}
.cop_cont_head .item {
  width: 375px;
}
.cop_cont_head /deep/ .el-input__inner {
  width: 282px;
  height: 40px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e7eaed;
}
.cop_cont_cont .item {
  padding: 18px 20px 20px;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid #e7eaed;
}
.cop_cont_cont .item.one {
  height: 64px;
  padding: 18px 20px 20px;
  display: flex;
  align-items: center;
}
.cop_cont_cont .list.content {
  width: 375px;
  border-left: 1px solid #e7eaed;
}
.item_title {
  margin-bottom: 10px;
}
.menu_box {
  width: 285px;
  padding: 20px 20px 5px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
  border-radius: 8px;
}
.menu_box /deep/ .el-checkbox__inner {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #8497ab;
}
.menu_box /deep/ .el-checkbox__inner::after {
  height: 12px;
  left: 6px;
  top: 0px;
  width: 6px;
}
.menu_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.menu_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1290c9;
  border-color: #1290c9;
}
.menu_box /deep/ .el-checkbox__label {
  font-size: 16px;
  color: #8497ab;
}
.menu_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1290c9;
}
.menu_box /deep/ .el-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.coll_child_name {
  display: inline-block;
  width: 215px;
}
.check_line {
  /* padding-top: 84px;
   */
   padding-top: 80px;
}
.line_txt {
  margin-bottom: 12px;
  height: 24px;
}
.del_ic {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #f56b6b;
  background-image: url(../../assets/img/detail/del_ic.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  margin-right: 4px;
}
</style>